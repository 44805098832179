import { motion } from "framer-motion";
import React from "react";

export function PageButton({ onClick, className }) {
  return (
    <motion.button
      whileHover={{ scale: 1.2 }}
      className={`flex justify-center items-center appearance-none bg-gray-50 rounded-full w-12 h-12 ${className}`}
      onClick={onClick}
    >
      <svg
        width="10"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 1.50037L7.94727 7.95734L1.5 14.3949"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </motion.button>
  );
}
